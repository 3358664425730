import * as _jquery2 from "jquery";

var _jquery = "default" in _jquery2 ? _jquery2.default : _jquery2;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

// wrap in UMD - see https://github.com/umdjs/umd/blob/master/jqueryPlugin.js
(function (factory) {
  if (exports) {
    exports = factory(_jquery, window, document);
  } else {
    factory(jQuery, window, document);
  }
})(function ($, window, document, undefined) {
  "use strict";

  var pluginName = "countrySelect",
      id = 1,
      // give each instance its own ID for namespaced event handling
  defaults = {
    // Default country
    defaultCountry: "",
    // Position the selected flag inside or outside of the input
    defaultStyling: "inside",
    // don't display these countries
    excludeCountries: [],
    // Display only these countries
    onlyCountries: [],
    // The countries at the top of the list. Defaults to United States and United Kingdom
    preferredCountries: ["us", "gb"],
    // localized country names e.g. { 'de': 'Deutschland' }
    localizedCountries: null,
    // Set the dropdown's width to be the same as the input. This is automatically enabled for small screens.
    responsiveDropdown: $(window).width() < 768 ? true : false
  },
      keys = {
    UP: 38,
    DOWN: 40,
    ENTER: 13,
    ESC: 27,
    BACKSPACE: 8,
    PLUS: 43,
    SPACE: 32,
    A: 65,
    Z: 90
  },
      windowLoaded = false; // keep track of if the window.load event has fired as impossible to check after the fact

  $(window).on("load", function () {
    windowLoaded = true;
  });

  function Plugin(element, options) {
    (this || _global).element = element;
    (this || _global).options = $.extend({}, defaults, options);
    (this || _global)._defaults = defaults; // event namespace

    (this || _global).ns = "." + pluginName + id++;
    (this || _global)._name = pluginName;
    this.init();
  }

  Plugin.prototype = {
    init: function () {
      // Process all the data: onlyCountries, excludeCountries, preferredCountries, defaultCountry etc
      this._processCountryData(); // Generate the markup


      this._generateMarkup(); // Set the initial state of the input value and the selected flag


      this._setInitialState(); // Start all of the event listeners: input keyup, selectedFlag click


      this._initListeners(); // Return this when the auto country is resolved.


      (this || _global).autoCountryDeferred = new $.Deferred(); // Get auto country.

      this._initAutoCountry(); // Keep track as the user types


      (this || _global).typedLetters = "";
      return (this || _global).autoCountryDeferred;
    },

    /********************
     *  PRIVATE METHODS
     ********************/
    // prepare all of the country data, including onlyCountries, excludeCountries, preferredCountries and
    // defaultCountry options
    _processCountryData: function () {
      // set the instances country data objects
      this._setInstanceCountryData(); // set the preferredCountries property


      this._setPreferredCountries(); // translate countries according to localizedCountries option


      if ((this || _global).options.localizedCountries) this._translateCountriesByLocale(); // sort countries by name

      if ((this || _global).options.onlyCountries.length || (this || _global).options.localizedCountries) {
        (this || _global).countries.sort((this || _global)._countryNameSort);
      }
    },
    // process onlyCountries array if present
    _setInstanceCountryData: function () {
      var that = this || _global;

      if ((this || _global).options.onlyCountries.length) {
        var newCountries = [];
        $.each((this || _global).options.onlyCountries, function (i, countryCode) {
          var countryData = that._getCountryData(countryCode, true);

          if (countryData) {
            newCountries.push(countryData);
          }
        });
        (this || _global).countries = newCountries;
      } else if ((this || _global).options.excludeCountries.length) {
        var lowerCaseExcludeCountries = (this || _global).options.excludeCountries.map(function (country) {
          return country.toLowerCase();
        });

        (this || _global).countries = allCountries.filter(function (country) {
          return lowerCaseExcludeCountries.indexOf(country.iso2) === -1;
        });
      } else {
        (this || _global).countries = allCountries;
      }
    },
    // Process preferred countries - iterate through the preferences,
    // fetching the country data for each one
    _setPreferredCountries: function () {
      var that = this || _global;
      (this || _global).preferredCountries = [];
      $.each((this || _global).options.preferredCountries, function (i, countryCode) {
        var countryData = that._getCountryData(countryCode, false);

        if (countryData) {
          that.preferredCountries.push(countryData);
        }
      });
    },

    // Translate Countries by object literal provided on config
    _translateCountriesByLocale() {
      for (let i = 0; i < (this || _global).countries.length; i++) {
        const iso = (this || _global).countries[i].iso2.toLowerCase();

        if ((this || _global).options.localizedCountries.hasOwnProperty(iso)) {
          (this || _global).countries[i].name = (this || _global).options.localizedCountries[iso];
        }
      }
    },

    // sort by country name
    _countryNameSort(a, b) {
      return a.name.localeCompare(b.name);
    },

    // generate all of the markup for the plugin: the selected flag overlay, and the dropdown
    _generateMarkup: function () {
      // Country input
      (this || _global).countryInput = $((this || _global).element); // containers (mostly for positioning)

      var mainClass = "country-select";

      if ((this || _global).options.defaultStyling) {
        mainClass += " " + (this || _global).options.defaultStyling;
      }

      (this || _global).countryInput.wrap($("<div>", {
        "class": mainClass
      }));

      var flagsContainer = $("<div>", {
        "class": "flag-dropdown"
      }).insertAfter((this || _global).countryInput); // currently selected flag (displayed to left of input)

      var selectedFlag = $("<div>", {
        "class": "selected-flag"
      }).appendTo(flagsContainer);
      (this || _global).selectedFlagInner = $("<div>", {
        "class": "flag"
      }).appendTo(selectedFlag); // CSS triangle

      $("<div>", {
        "class": "arrow"
      }).appendTo(selectedFlag); // country list contains: preferred countries, then divider, then all countries

      (this || _global).countryList = $("<ul>", {
        "class": "country-list v-hide"
      }).appendTo(flagsContainer);

      if ((this || _global).preferredCountries.length) {
        this._appendListItems((this || _global).preferredCountries, "preferred");

        $("<li>", {
          "class": "divider"
        }).appendTo((this || _global).countryList);
      }

      this._appendListItems((this || _global).countries, ""); // Add the hidden input for the country code


      (this || _global).countryCodeInput = $("#" + (this || _global).countryInput.attr("id") + "_code");

      if (!(this || _global).countryCodeInput) {
        (this || _global).countryCodeInput = $("<input type=\"hidden\" id=\"" + (this || _global).countryInput.attr("id") + "_code\" name=\"" + (this || _global).countryInput.attr("name") + "_code\" value=\"\" />");

        (this || _global).countryCodeInput.insertAfter((this || _global).countryInput);
      } // now we can grab the dropdown height, and hide it properly


      (this || _global).dropdownHeight = (this || _global).countryList.outerHeight(); // set the dropdown width according to the input if responsiveDropdown option is present or if it's a small screen

      if ((this || _global).options.responsiveDropdown) {
        $(window).resize(function () {
          $(".country-select").each(function () {
            var dropdownWidth = (this || _global).offsetWidth;
            $(this || _global).find(".country-list").css("width", dropdownWidth + "px");
          });
        }).resize();
      }

      (this || _global).countryList.removeClass("v-hide").addClass("hide"); // this is useful in lots of places


      (this || _global).countryListItems = (this || _global).countryList.children(".country");
    },
    // add a country <li> to the countryList <ul> container
    _appendListItems: function (countries, className) {
      // Generate DOM elements as a large temp string, so that there is only
      // one DOM insert event
      var tmp = ""; // for each country

      $.each(countries, function (i, c) {
        // open the list item
        tmp += "<li class=\"country " + className + "\" data-country-code=\"" + c.iso2 + "\">"; // add the flag

        tmp += "<div class=\"flag " + c.iso2 + "\"></div>"; // and the country name

        tmp += "<span class=\"country-name\">" + c.name + "</span>"; // close the list item

        tmp += "</li>";
      });

      (this || _global).countryList.append(tmp);
    },
    // set the initial state of the input value and the selected flag
    _setInitialState: function () {
      var flagIsSet = false; // If the input is pre-populated, then just update the selected flag

      if ((this || _global).countryInput.val()) {
        flagIsSet = this._updateFlagFromInputVal();
      } // If the country code input is pre-populated, update the name and the selected flag


      var selectedCode = (this || _global).countryCodeInput.val();

      if (selectedCode) {
        this.selectCountry(selectedCode);
      }

      if (!flagIsSet) {
        // flag is not set, so set to the default country
        var defaultCountry; // check the defaultCountry option, else fall back to the first in the list

        if ((this || _global).options.defaultCountry) {
          defaultCountry = this._getCountryData((this || _global).options.defaultCountry, false); // Did we not find the requested default country?

          if (!defaultCountry) {
            defaultCountry = (this || _global).preferredCountries.length ? (this || _global).preferredCountries[0] : (this || _global).countries[0];
          }
        } else {
          defaultCountry = (this || _global).preferredCountries.length ? (this || _global).preferredCountries[0] : (this || _global).countries[0];
        }

        (this || _global).defaultCountry = defaultCountry.iso2;
      }
    },
    // initialise the main event listeners: input keyup, and click selected flag
    _initListeners: function () {
      var that = this || _global; // Update flag on keyup.
      // Use keyup instead of keypress because we want to update on backspace
      // and instead of keydown because the value hasn't updated when that
      // event is fired.
      // NOTE: better to have this one listener all the time instead of
      // starting it on focus and stopping it on blur, because then you've
      // got two listeners (focus and blur)

      (this || _global).countryInput.on("keyup" + (this || _global).ns, function () {
        that._updateFlagFromInputVal();
      }); // toggle country dropdown on click


      var selectedFlag = (this || _global).selectedFlagInner.parent();

      selectedFlag.on("click" + (this || _global).ns, function (e) {
        // only intercept this event if we're opening the dropdown
        // else let it bubble up to the top ("click-off-to-close" listener)
        // we cannot just stopPropagation as it may be needed to close another instance
        if (that.countryList.hasClass("hide") && !that.countryInput.prop("disabled")) {
          that._showDropdown();
        }
      }); // Despite above note, added blur to ensure partially spelled country
      // with correctly chosen flag is spelled out on blur. Also, correctly
      // selects flag when field is autofilled

      (this || _global).countryInput.on("blur" + (this || _global).ns, function () {
        if (that.countryInput.val() != that.getSelectedCountryData().name) {
          that.setCountry(that.countryInput.val());
        }

        that.countryInput.val(that.getSelectedCountryData().name);
      });
    },
    _initAutoCountry: function () {
      if ((this || _global).options.initialCountry === "auto") {
        this._loadAutoCountry();
      } else {
        if ((this || _global).defaultCountry) {
          this.selectCountry((this || _global).defaultCountry);
        }

        (this || _global).autoCountryDeferred.resolve();
      }
    },
    // perform the geo ip lookup
    _loadAutoCountry: function () {
      var that = this || _global; // 3 options:
      // 1) already loaded (we're done)
      // 2) not already started loading (start)
      // 3) already started loading (do nothing - just wait for loading callback to fire)

      if ($.fn[pluginName].autoCountry) {
        this.handleAutoCountry();
      } else if (!$.fn[pluginName].startedLoadingAutoCountry) {
        // don't do this twice!
        $.fn[pluginName].startedLoadingAutoCountry = true;

        if (typeof (this || _global).options.geoIpLookup === "function") {
          (this || _global).options.geoIpLookup(function (countryCode) {
            $.fn[pluginName].autoCountry = countryCode.toLowerCase(); // tell all instances the auto country is ready
            // TODO: this should just be the current instances
            // UPDATE: use setTimeout in case their geoIpLookup function calls this callback straight away (e.g. if they have already done the geo ip lookup somewhere else). Using setTimeout means that the current thread of execution will finish before executing this, which allows the plugin to finish initialising.

            setTimeout(function () {
              $(".country-select input").countrySelect("handleAutoCountry");
            });
          });
        }
      }
    },
    // Focus input and put the cursor at the end
    _focus: function () {
      (this || _global).countryInput.focus();

      var input = (this || _global).countryInput[0]; // works for Chrome, FF, Safari, IE9+

      if (input.setSelectionRange) {
        var len = (this || _global).countryInput.val().length;

        input.setSelectionRange(len, len);
      }
    },
    // Show the dropdown
    _showDropdown: function () {
      this._setDropdownPosition(); // update highlighting and scroll to active list item


      var activeListItem = (this || _global).countryList.children(".active");

      this._highlightListItem(activeListItem); // show it


      (this || _global).countryList.removeClass("hide");

      this._scrollTo(activeListItem); // bind all the dropdown-related listeners: mouseover, click, click-off, keydown


      this._bindDropdownListeners(); // update the arrow


      (this || _global).selectedFlagInner.parent().children(".arrow").addClass("up");
    },
    // decide where to position dropdown (depends on position within viewport, and scroll)
    _setDropdownPosition: function () {
      var inputTop = (this || _global).countryInput.offset().top,
          windowTop = $(window).scrollTop(),
          dropdownFitsBelow = inputTop + (this || _global).countryInput.outerHeight() + (this || _global).dropdownHeight < windowTop + $(window).height(),
          dropdownFitsAbove = inputTop - (this || _global).dropdownHeight > windowTop; // dropdownHeight - 1 for border


      var cssTop = !dropdownFitsBelow && dropdownFitsAbove ? "-" + ((this || _global).dropdownHeight - 1) + "px" : "";

      (this || _global).countryList.css("top", cssTop);
    },
    // we only bind dropdown listeners when the dropdown is open
    _bindDropdownListeners: function () {
      var that = this || _global; // when mouse over a list item, just highlight that one
      // we add the class "highlight", so if they hit "enter" we know which one to select

      (this || _global).countryList.on("mouseover" + (this || _global).ns, ".country", function (e) {
        that._highlightListItem($(this || _global));
      }); // listen for country selection


      (this || _global).countryList.on("click" + (this || _global).ns, ".country", function (e) {
        that._selectListItem($(this || _global));
      }); // click off to close
      // (except when this initial opening click is bubbling up)
      // we cannot just stopPropagation as it may be needed to close another instance


      var isOpening = true;
      $("html").on("click" + (this || _global).ns, function (e) {
        e.preventDefault();

        if (!isOpening) {
          that._closeDropdown();
        }

        isOpening = false;
      }); // Listen for up/down scrolling, enter to select, or letters to jump to country name.
      // Use keydown as keypress doesn't fire for non-char keys and we want to catch if they
      // just hit down and hold it to scroll down (no keyup event).
      // Listen on the document because that's where key events are triggered if no input has focus

      $(document).on("keydown" + (this || _global).ns, function (e) {
        // prevent down key from scrolling the whole page,
        // and enter key from submitting a form etc
        e.preventDefault();

        if (e.which == keys.UP || e.which == keys.DOWN) {
          // up and down to navigate
          that._handleUpDownKey(e.which);
        } else if (e.which == keys.ENTER) {
          // enter to select
          that._handleEnterKey();
        } else if (e.which == keys.ESC) {
          // esc to close
          that._closeDropdown();
        } else if (e.which >= keys.A && e.which <= keys.Z || e.which === keys.SPACE) {
          that.typedLetters += String.fromCharCode(e.which);

          that._filterCountries(that.typedLetters);
        } else if (e.which === keys.BACKSPACE) {
          that.typedLetters = that.typedLetters.slice(0, -1);

          that._filterCountries(that.typedLetters);
        }
      });
    },
    // Highlight the next/prev item in the list (and ensure it is visible)
    _handleUpDownKey: function (key) {
      var current = (this || _global).countryList.children(".highlight").first();

      var next = key == keys.UP ? current.prev() : current.next();

      if (next.length) {
        // skip the divider
        if (next.hasClass("divider")) {
          next = key == keys.UP ? next.prev() : next.next();
        }

        this._highlightListItem(next);

        this._scrollTo(next);
      }
    },
    // select the currently highlighted item
    _handleEnterKey: function () {
      var currentCountry = (this || _global).countryList.children(".highlight").first();

      if (currentCountry.length) {
        this._selectListItem(currentCountry);
      }
    },
    _filterCountries: function (letters) {
      var countries = (this || _global).countryListItems.filter(function () {
        return $(this || _global).text().toUpperCase().indexOf(letters) === 0 && !$(this || _global).hasClass("preferred");
      });

      if (countries.length) {
        // if one is already highlighted, then we want the next one
        var highlightedCountry = countries.filter(".highlight").first(),
            listItem;

        if (highlightedCountry && highlightedCountry.next() && highlightedCountry.next().text().toUpperCase().indexOf(letters) === 0) {
          listItem = highlightedCountry.next();
        } else {
          listItem = countries.first();
        } // update highlighting and scroll


        this._highlightListItem(listItem);

        this._scrollTo(listItem);
      }
    },
    // Update the selected flag using the input's current value
    _updateFlagFromInputVal: function () {
      var that = this || _global; // try and extract valid country from input

      var value = (this || _global).countryInput.val().replace(/(?=[() ])/g, "\\");

      if (value) {
        var countryCodes = [];
        var matcher = new RegExp(value, "i"); // Check for ISO codes only

        if (value.length <= 2) {
          for (var i = 0; i < (this || _global).countries.length; i++) {
            if ((this || _global).countries[i].iso2.match(matcher)) {
              countryCodes.push((this || _global).countries[i].iso2);
            }
          }
        } // If no previous matches / larger than 2 chars, then search country name


        if (countryCodes.length == 0) {
          for (var i = 0; i < (this || _global).countries.length; i++) {
            if ((this || _global).countries[i].name.match(matcher)) {
              countryCodes.push((this || _global).countries[i].iso2);
            }
          }
        } // Check if one of the matching countries is already selected


        var alreadySelected = false;
        $.each(countryCodes, function (i, c) {
          if (that.selectedFlagInner.hasClass(c)) {
            alreadySelected = true;
          }
        });

        if (!alreadySelected) {
          this._selectFlag(countryCodes[0]);

          (this || _global).countryCodeInput.val(countryCodes[0]).trigger("change");
        } // Matching country found


        return true;
      } // No match found


      return false;
    },
    // remove highlighting from other list items and highlight the given item
    _highlightListItem: function (listItem) {
      (this || _global).countryListItems.removeClass("highlight");

      listItem.addClass("highlight");
    },
    // find the country data for the given country code
    // the ignoreOnlyCountriesOption is only used during init() while parsing the onlyCountries array
    _getCountryData: function (countryCode, ignoreOnlyCountriesOption) {
      var countryList = ignoreOnlyCountriesOption ? allCountries : (this || _global).countries;

      for (var i = 0; i < countryList.length; i++) {
        if (countryList[i].iso2 == countryCode) {
          return countryList[i];
        }
      }

      return null;
    },
    // update the selected flag and the active list item
    _selectFlag: function (countryCode) {
      if (!countryCode) {
        return false;
      }

      (this || _global).selectedFlagInner.attr("class", "flag " + countryCode); // update the title attribute


      var countryData = this._getCountryData(countryCode);

      (this || _global).selectedFlagInner.parent().attr("title", countryData.name); // update the active list item


      var listItem = (this || _global).countryListItems.children(".flag." + countryCode).first().parent();

      (this || _global).countryListItems.removeClass("active");

      listItem.addClass("active");
    },
    // called when the user selects a list item from the dropdown
    _selectListItem: function (listItem) {
      // update selected flag and active list item
      var countryCode = listItem.attr("data-country-code");

      this._selectFlag(countryCode);

      this._closeDropdown(); // update input value


      this._updateName(countryCode);

      (this || _global).countryInput.trigger("change");

      (this || _global).countryCodeInput.trigger("change"); // focus the input


      this._focus();
    },
    // close the dropdown and unbind any listeners
    _closeDropdown: function () {
      (this || _global).countryList.addClass("hide"); // update the arrow


      (this || _global).selectedFlagInner.parent().children(".arrow").removeClass("up"); // unbind event listeners


      $(document).off("keydown" + (this || _global).ns);
      $("html").off("click" + (this || _global).ns); // unbind both hover and click listeners

      (this || _global).countryList.off((this || _global).ns);

      (this || _global).typedLetters = "";
    },
    // check if an element is visible within its container, else scroll until it is
    _scrollTo: function (element) {
      if (!element || !element.offset()) {
        return;
      }

      var container = (this || _global).countryList,
          containerHeight = container.height(),
          containerTop = container.offset().top,
          containerBottom = containerTop + containerHeight,
          elementHeight = element.outerHeight(),
          elementTop = element.offset().top,
          elementBottom = elementTop + elementHeight,
          newScrollTop = elementTop - containerTop + container.scrollTop();

      if (elementTop < containerTop) {
        // scroll up
        container.scrollTop(newScrollTop);
      } else if (elementBottom > containerBottom) {
        // scroll down
        var heightDifference = containerHeight - elementHeight;
        container.scrollTop(newScrollTop - heightDifference);
      }
    },
    // Replace any existing country name with the new one
    _updateName: function (countryCode) {
      (this || _global).countryCodeInput.val(countryCode).trigger("change");

      (this || _global).countryInput.val(this._getCountryData(countryCode).name);
    },

    /********************
     *  PUBLIC METHODS
     ********************/
    // this is called when the geoip call returns
    handleAutoCountry: function () {
      if ((this || _global).options.initialCountry === "auto") {
        // we must set this even if there is an initial val in the input: in case the initial val is invalid and they delete it - they should see their auto country
        (this || _global).defaultCountry = $.fn[pluginName].autoCountry; // if there's no initial value in the input, then update the flag

        if (!(this || _global).countryInput.val()) {
          this.selectCountry((this || _global).defaultCountry);
        }

        (this || _global).autoCountryDeferred.resolve();
      }
    },
    // get the country data for the currently selected flag
    getSelectedCountryData: function () {
      // rely on the fact that we only set 2 classes on the selected flag element:
      // the first is "flag" and the second is the 2-char country code
      var countryCode = (this || _global).selectedFlagInner.attr("class").split(" ")[1];

      return this._getCountryData(countryCode);
    },
    // update the selected flag
    selectCountry: function (countryCode) {
      countryCode = countryCode.toLowerCase(); // check if already selected

      if (!(this || _global).selectedFlagInner.hasClass(countryCode)) {
        this._selectFlag(countryCode);

        this._updateName(countryCode);
      }
    },
    // set the input value and update the flag
    setCountry: function (country) {
      (this || _global).countryInput.val(country);

      this._updateFlagFromInputVal();
    },
    // remove plugin
    destroy: function () {
      // stop listeners
      (this || _global).countryInput.off((this || _global).ns);

      (this || _global).selectedFlagInner.parent().off((this || _global).ns); // remove markup


      var container = (this || _global).countryInput.parent();

      container.before((this || _global).countryInput).remove();
    }
  }; // adapted to allow public functions
  // using https://github.com/jquery-boilerplate/jquery-boilerplate/wiki/Extending-jQuery-Boilerplate

  $.fn[pluginName] = function (options) {
    var args = arguments; // Is the first parameter an object (options), or was omitted,
    // instantiate a new instance of the plugin.

    if (options === undefined || typeof options === "object") {
      return this.each(function () {
        if (!$.data(this || _global, "plugin_" + pluginName)) {
          $.data(this || _global, "plugin_" + pluginName, new Plugin(this || _global, options));
        }
      });
    } else if (typeof options === "string" && options[0] !== "_" && options !== "init") {
      // If the first parameter is a string and it doesn't start
      // with an underscore or "contains" the `init`-function,
      // treat this as a call to a public method.
      // Cache the method call to make it possible to return a value
      var returns;
      this.each(function () {
        var instance = $.data(this || _global, "plugin_" + pluginName); // Tests that there's already a plugin-instance
        // and checks that the requested public method exists

        if (instance instanceof Plugin && typeof instance[options] === "function") {
          // Call the method of our plugin instance,
          // and pass it the supplied arguments.
          returns = instance[options].apply(instance, Array.prototype.slice.call(args, 1));
        } // Allow instances to be destroyed via the 'destroy' method


        if (options === "destroy") {
          $.data(this || _global, "plugin_" + pluginName, null);
        }
      }); // If the earlier cached method gives a value back return the value,
      // otherwise return this to preserve chainability.

      return returns !== undefined ? returns : this || _global;
    }
  };
  /********************
    *  STATIC METHODS
    ********************/
  // get the country data object


  $.fn[pluginName].getCountryData = function () {
    return allCountries;
  }; // set the country data object


  $.fn[pluginName].setCountryData = function (obj) {
    allCountries = obj;
  }; // Tell JSHint to ignore this warning: "character may get silently deleted by one or more browsers"
  // jshint -W100
  // Array of country objects for the flag dropdown.
  // Each contains a name and country code (ISO 3166-1 alpha-2).
  //
  // Note: using single char property names to keep filesize down
  // n = name
  // i = iso2 (2-char country code)


  var allCountries = $.each([{
    n: "Afghanistan (\u202B\u0627\u0641\u063A\u0627\u0646\u0633\u062A\u0627\u0646\u202C\u200E)",
    i: "af"
  }, {
    n: "\xC5land Islands (\xC5land)",
    i: "ax"
  }, {
    n: "Albania (Shqip\xEBri)",
    i: "al"
  }, {
    n: "Algeria (\u202B\u0627\u0644\u062C\u0632\u0627\u0626\u0631\u202C\u200E)",
    i: "dz"
  }, {
    n: "American Samoa",
    i: "as"
  }, {
    n: "Andorra",
    i: "ad"
  }, {
    n: "Angola",
    i: "ao"
  }, {
    n: "Anguilla",
    i: "ai"
  }, {
    n: "Antarctica",
    i: "aq"
  }, {
    n: "Antigua and Barbuda",
    i: "ag"
  }, {
    n: "Argentina",
    i: "ar"
  }, {
    n: "Armenia (\u0540\u0561\u0575\u0561\u057D\u057F\u0561\u0576)",
    i: "am"
  }, {
    n: "Aruba",
    i: "aw"
  }, {
    n: "Australia",
    i: "au"
  }, {
    n: "Austria (\xD6sterreich)",
    i: "at"
  }, {
    n: "Azerbaijan (Az\u0259rbaycan)",
    i: "az"
  }, {
    n: "Bahamas",
    i: "bs"
  }, {
    n: "Bahrain (\u202B\u0627\u0644\u0628\u062D\u0631\u064A\u0646\u202C\u200E)",
    i: "bh"
  }, {
    n: "Bangladesh (\u09AC\u09BE\u0982\u09B2\u09BE\u09A6\u09C7\u09B6)",
    i: "bd"
  }, {
    n: "Barbados",
    i: "bb"
  }, {
    n: "Belarus (\u0411\u0435\u043B\u0430\u0440\u0443\u0441\u044C)",
    i: "by"
  }, {
    n: "Belgium (Belgi\xEB)",
    i: "be"
  }, {
    n: "Belize",
    i: "bz"
  }, {
    n: "Benin (B\xE9nin)",
    i: "bj"
  }, {
    n: "Bermuda",
    i: "bm"
  }, {
    n: "Bhutan (\u0F60\u0F56\u0FB2\u0F74\u0F42)",
    i: "bt"
  }, {
    n: "Bolivia",
    i: "bo"
  }, {
    n: "Bosnia and Herzegovina (\u0411\u043E\u0441\u043D\u0430 \u0438 \u0425\u0435\u0440\u0446\u0435\u0433\u043E\u0432\u0438\u043D\u0430)",
    i: "ba"
  }, {
    n: "Botswana",
    i: "bw"
  }, {
    n: "Bouvet Island (Bouvet\xF8ya)",
    i: "bv"
  }, {
    n: "Brazil (Brasil)",
    i: "br"
  }, {
    n: "British Indian Ocean Territory",
    i: "io"
  }, {
    n: "British Virgin Islands",
    i: "vg"
  }, {
    n: "Brunei",
    i: "bn"
  }, {
    n: "Bulgaria (\u0411\u044A\u043B\u0433\u0430\u0440\u0438\u044F)",
    i: "bg"
  }, {
    n: "Burkina Faso",
    i: "bf"
  }, {
    n: "Burundi (Uburundi)",
    i: "bi"
  }, {
    n: "Cambodia (\u1780\u1798\u17D2\u1796\u17BB\u1787\u17B6)",
    i: "kh"
  }, {
    n: "Cameroon (Cameroun)",
    i: "cm"
  }, {
    n: "Canada",
    i: "ca"
  }, {
    n: "Cape Verde (Kabu Verdi)",
    i: "cv"
  }, {
    n: "Caribbean Netherlands",
    i: "bq"
  }, {
    n: "Cayman Islands",
    i: "ky"
  }, {
    n: "Central African Republic (R\xE9publique Centrafricaine)",
    i: "cf"
  }, {
    n: "Chad (Tchad)",
    i: "td"
  }, {
    n: "Chile",
    i: "cl"
  }, {
    n: "China (\u4E2D\u56FD)",
    i: "cn"
  }, {
    n: "Christmas Island",
    i: "cx"
  }, {
    n: "Cocos (Keeling) Islands (Kepulauan Cocos (Keeling))",
    i: "cc"
  }, {
    n: "Colombia",
    i: "co"
  }, {
    n: "Comoros (\u202B\u062C\u0632\u0631 \u0627\u0644\u0642\u0645\u0631\u202C\u200E)",
    i: "km"
  }, {
    n: "Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)",
    i: "cd"
  }, {
    n: "Congo (Republic) (Congo-Brazzaville)",
    i: "cg"
  }, {
    n: "Cook Islands",
    i: "ck"
  }, {
    n: "Costa Rica",
    i: "cr"
  }, {
    n: "C\xF4te d\u2019Ivoire",
    i: "ci"
  }, {
    n: "Croatia (Hrvatska)",
    i: "hr"
  }, {
    n: "Cuba",
    i: "cu"
  }, {
    n: "Cura\xE7ao",
    i: "cw"
  }, {
    n: "Cyprus (\u039A\u03CD\u03C0\u03C1\u03BF\u03C2)",
    i: "cy"
  }, {
    n: "Czech Republic (\u010Cesk\xE1 republika)",
    i: "cz"
  }, {
    n: "Denmark (Danmark)",
    i: "dk"
  }, {
    n: "Djibouti",
    i: "dj"
  }, {
    n: "Dominica",
    i: "dm"
  }, {
    n: "Dominican Republic (Rep\xFAblica Dominicana)",
    i: "do"
  }, {
    n: "Ecuador",
    i: "ec"
  }, {
    n: "Egypt (\u202B\u0645\u0635\u0631\u202C\u200E)",
    i: "eg"
  }, {
    n: "El Salvador",
    i: "sv"
  }, {
    n: "Equatorial Guinea (Guinea Ecuatorial)",
    i: "gq"
  }, {
    n: "Eritrea",
    i: "er"
  }, {
    n: "Estonia (Eesti)",
    i: "ee"
  }, {
    n: "Ethiopia",
    i: "et"
  }, {
    n: "Falkland Islands (Islas Malvinas)",
    i: "fk"
  }, {
    n: "Faroe Islands (F\xF8royar)",
    i: "fo"
  }, {
    n: "Fiji",
    i: "fj"
  }, {
    n: "Finland (Suomi)",
    i: "fi"
  }, {
    n: "France",
    i: "fr"
  }, {
    n: "French Guiana (Guyane fran\xE7aise)",
    i: "gf"
  }, {
    n: "French Polynesia (Polyn\xE9sie fran\xE7aise)",
    i: "pf"
  }, {
    n: "French Southern Territories (Terres australes fran\xE7aises)",
    i: "tf"
  }, {
    n: "Gabon",
    i: "ga"
  }, {
    n: "Gambia",
    i: "gm"
  }, {
    n: "Georgia (\u10E1\u10D0\u10E5\u10D0\u10E0\u10D7\u10D5\u10D4\u10DA\u10DD)",
    i: "ge"
  }, {
    n: "Germany (Deutschland)",
    i: "de"
  }, {
    n: "Ghana (Gaana)",
    i: "gh"
  }, {
    n: "Gibraltar",
    i: "gi"
  }, {
    n: "Greece (\u0395\u03BB\u03BB\u03AC\u03B4\u03B1)",
    i: "gr"
  }, {
    n: "Greenland (Kalaallit Nunaat)",
    i: "gl"
  }, {
    n: "Grenada",
    i: "gd"
  }, {
    n: "Guadeloupe",
    i: "gp"
  }, {
    n: "Guam",
    i: "gu"
  }, {
    n: "Guatemala",
    i: "gt"
  }, {
    n: "Guernsey",
    i: "gg"
  }, {
    n: "Guinea (Guin\xE9e)",
    i: "gn"
  }, {
    n: "Guinea-Bissau (Guin\xE9 Bissau)",
    i: "gw"
  }, {
    n: "Guyana",
    i: "gy"
  }, {
    n: "Haiti",
    i: "ht"
  }, {
    n: "Heard Island and Mcdonald Islands",
    i: "hm"
  }, {
    n: "Honduras",
    i: "hn"
  }, {
    n: "Hong Kong (\u9999\u6E2F)",
    i: "hk"
  }, {
    n: "Hungary (Magyarorsz\xE1g)",
    i: "hu"
  }, {
    n: "Iceland (\xCDsland)",
    i: "is"
  }, {
    n: "India (\u092D\u093E\u0930\u0924)",
    i: "in"
  }, {
    n: "Indonesia",
    i: "id"
  }, {
    n: "Iran (\u202B\u0627\u06CC\u0631\u0627\u0646\u202C\u200E)",
    i: "ir"
  }, {
    n: "Iraq (\u202B\u0627\u0644\u0639\u0631\u0627\u0642\u202C\u200E)",
    i: "iq"
  }, {
    n: "Ireland",
    i: "ie"
  }, {
    n: "Isle of Man",
    i: "im"
  }, {
    n: "Israel (\u202B\u05D9\u05E9\u05E8\u05D0\u05DC\u202C\u200E)",
    i: "il"
  }, {
    n: "Italy (Italia)",
    i: "it"
  }, {
    n: "Jamaica",
    i: "jm"
  }, {
    n: "Japan (\u65E5\u672C)",
    i: "jp"
  }, {
    n: "Jersey",
    i: "je"
  }, {
    n: "Jordan (\u202B\u0627\u0644\u0623\u0631\u062F\u0646\u202C\u200E)",
    i: "jo"
  }, {
    n: "Kazakhstan (\u041A\u0430\u0437\u0430\u0445\u0441\u0442\u0430\u043D)",
    i: "kz"
  }, {
    n: "Kenya",
    i: "ke"
  }, {
    n: "Kiribati",
    i: "ki"
  }, {
    n: "Kosovo (Kosov\xEB)",
    i: "xk"
  }, {
    n: "Kuwait (\u202B\u0627\u0644\u0643\u0648\u064A\u062A\u202C\u200E)",
    i: "kw"
  }, {
    n: "Kyrgyzstan (\u041A\u044B\u0440\u0433\u044B\u0437\u0441\u0442\u0430\u043D)",
    i: "kg"
  }, {
    n: "Laos (\u0EA5\u0EB2\u0EA7)",
    i: "la"
  }, {
    n: "Latvia (Latvija)",
    i: "lv"
  }, {
    n: "Lebanon (\u202B\u0644\u0628\u0646\u0627\u0646\u202C\u200E)",
    i: "lb"
  }, {
    n: "Lesotho",
    i: "ls"
  }, {
    n: "Liberia",
    i: "lr"
  }, {
    n: "Libya (\u202B\u0644\u064A\u0628\u064A\u0627\u202C\u200E)",
    i: "ly"
  }, {
    n: "Liechtenstein",
    i: "li"
  }, {
    n: "Lithuania (Lietuva)",
    i: "lt"
  }, {
    n: "Luxembourg",
    i: "lu"
  }, {
    n: "Macau (\u6FB3\u9580)",
    i: "mo"
  }, {
    n: "Macedonia (FYROM) (\u041C\u0430\u043A\u0435\u0434\u043E\u043D\u0438\u0458\u0430)",
    i: "mk"
  }, {
    n: "Madagascar (Madagasikara)",
    i: "mg"
  }, {
    n: "Malawi",
    i: "mw"
  }, {
    n: "Malaysia",
    i: "my"
  }, {
    n: "Maldives",
    i: "mv"
  }, {
    n: "Mali",
    i: "ml"
  }, {
    n: "Malta",
    i: "mt"
  }, {
    n: "Marshall Islands",
    i: "mh"
  }, {
    n: "Martinique",
    i: "mq"
  }, {
    n: "Mauritania (\u202B\u0645\u0648\u0631\u064A\u062A\u0627\u0646\u064A\u0627\u202C\u200E)",
    i: "mr"
  }, {
    n: "Mauritius (Moris)",
    i: "mu"
  }, {
    n: "Mayotte",
    i: "yt"
  }, {
    n: "Mexico (M\xE9xico)",
    i: "mx"
  }, {
    n: "Micronesia",
    i: "fm"
  }, {
    n: "Moldova (Republica Moldova)",
    i: "md"
  }, {
    n: "Monaco",
    i: "mc"
  }, {
    n: "Mongolia (\u041C\u043E\u043D\u0433\u043E\u043B)",
    i: "mn"
  }, {
    n: "Montenegro (Crna Gora)",
    i: "me"
  }, {
    n: "Montserrat",
    i: "ms"
  }, {
    n: "Morocco (\u202B\u0627\u0644\u0645\u063A\u0631\u0628\u202C\u200E)",
    i: "ma"
  }, {
    n: "Mozambique (Mo\xE7ambique)",
    i: "mz"
  }, {
    n: "Myanmar (Burma) (\u1019\u103C\u1014\u103A\u1019\u102C)",
    i: "mm"
  }, {
    n: "Namibia (Namibi\xEB)",
    i: "na"
  }, {
    n: "Nauru",
    i: "nr"
  }, {
    n: "Nepal (\u0928\u0947\u092A\u093E\u0932)",
    i: "np"
  }, {
    n: "Netherlands (Nederland)",
    i: "nl"
  }, {
    n: "New Caledonia (Nouvelle-Cal\xE9donie)",
    i: "nc"
  }, {
    n: "New Zealand",
    i: "nz"
  }, {
    n: "Nicaragua",
    i: "ni"
  }, {
    n: "Niger (Nijar)",
    i: "ne"
  }, {
    n: "Nigeria",
    i: "ng"
  }, {
    n: "Niue",
    i: "nu"
  }, {
    n: "Norfolk Island",
    i: "nf"
  }, {
    n: "North Korea (\uC870\uC120 \uBBFC\uC8FC\uC8FC\uC758 \uC778\uBBFC \uACF5\uD654\uAD6D)",
    i: "kp"
  }, {
    n: "Northern Mariana Islands",
    i: "mp"
  }, {
    n: "Norway (Norge)",
    i: "no"
  }, {
    n: "Oman (\u202B\u0639\u064F\u0645\u0627\u0646\u202C\u200E)",
    i: "om"
  }, {
    n: "Pakistan (\u202B\u067E\u0627\u06A9\u0633\u062A\u0627\u0646\u202C\u200E)",
    i: "pk"
  }, {
    n: "Palau",
    i: "pw"
  }, {
    n: "Palestine (\u202B\u0641\u0644\u0633\u0637\u064A\u0646\u202C\u200E)",
    i: "ps"
  }, {
    n: "Panama (Panam\xE1)",
    i: "pa"
  }, {
    n: "Papua New Guinea",
    i: "pg"
  }, {
    n: "Paraguay",
    i: "py"
  }, {
    n: "Peru (Per\xFA)",
    i: "pe"
  }, {
    n: "Philippines",
    i: "ph"
  }, {
    n: "Pitcairn Islands",
    i: "pn"
  }, {
    n: "Poland (Polska)",
    i: "pl"
  }, {
    n: "Portugal",
    i: "pt"
  }, {
    n: "Puerto Rico",
    i: "pr"
  }, {
    n: "Qatar (\u202B\u0642\u0637\u0631\u202C\u200E)",
    i: "qa"
  }, {
    n: "R\xE9union (La R\xE9union)",
    i: "re"
  }, {
    n: "Romania (Rom\xE2nia)",
    i: "ro"
  }, {
    n: "Russia (\u0420\u043E\u0441\u0441\u0438\u044F)",
    i: "ru"
  }, {
    n: "Rwanda",
    i: "rw"
  }, {
    n: "Saint Barth\xE9lemy (Saint-Barth\xE9lemy)",
    i: "bl"
  }, {
    n: "Saint Helena",
    i: "sh"
  }, {
    n: "Saint Kitts and Nevis",
    i: "kn"
  }, {
    n: "Saint Lucia",
    i: "lc"
  }, {
    n: "Saint Martin (Saint-Martin (partie fran\xE7aise))",
    i: "mf"
  }, {
    n: "Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)",
    i: "pm"
  }, {
    n: "Saint Vincent and the Grenadines",
    i: "vc"
  }, {
    n: "Samoa",
    i: "ws"
  }, {
    n: "San Marino",
    i: "sm"
  }, {
    n: "S\xE3o Tom\xE9 and Pr\xEDncipe (S\xE3o Tom\xE9 e Pr\xEDncipe)",
    i: "st"
  }, {
    n: "Saudi Arabia (\u202B\u0627\u0644\u0645\u0645\u0644\u0643\u0629 \u0627\u0644\u0639\u0631\u0628\u064A\u0629 \u0627\u0644\u0633\u0639\u0648\u062F\u064A\u0629\u202C\u200E)",
    i: "sa"
  }, {
    n: "Senegal (S\xE9n\xE9gal)",
    i: "sn"
  }, {
    n: "Serbia (\u0421\u0440\u0431\u0438\u0458\u0430)",
    i: "rs"
  }, {
    n: "Seychelles",
    i: "sc"
  }, {
    n: "Sierra Leone",
    i: "sl"
  }, {
    n: "Singapore",
    i: "sg"
  }, {
    n: "Sint Maarten",
    i: "sx"
  }, {
    n: "Slovakia (Slovensko)",
    i: "sk"
  }, {
    n: "Slovenia (Slovenija)",
    i: "si"
  }, {
    n: "Solomon Islands",
    i: "sb"
  }, {
    n: "Somalia (Soomaaliya)",
    i: "so"
  }, {
    n: "South Africa",
    i: "za"
  }, {
    n: "South Georgia & South Sandwich Islands",
    i: "gs"
  }, {
    n: "South Korea (\uB300\uD55C\uBBFC\uAD6D)",
    i: "kr"
  }, {
    n: "South Sudan (\u202B\u062C\u0646\u0648\u0628 \u0627\u0644\u0633\u0648\u062F\u0627\u0646\u202C\u200E)",
    i: "ss"
  }, {
    n: "Spain (Espa\xF1a)",
    i: "es"
  }, {
    n: "Sri Lanka (\u0DC1\u0DCA\u200D\u0DBB\u0DD3 \u0DBD\u0D82\u0D9A\u0DCF\u0DC0)",
    i: "lk"
  }, {
    n: "Sudan (\u202B\u0627\u0644\u0633\u0648\u062F\u0627\u0646\u202C\u200E)",
    i: "sd"
  }, {
    n: "Suriname",
    i: "sr"
  }, {
    n: "Svalbard and Jan Mayen (Svalbard og Jan Mayen)",
    i: "sj"
  }, {
    n: "Swaziland",
    i: "sz"
  }, {
    n: "Sweden (Sverige)",
    i: "se"
  }, {
    n: "Switzerland (Schweiz)",
    i: "ch"
  }, {
    n: "Syria (\u202B\u0633\u0648\u0631\u064A\u0627\u202C\u200E)",
    i: "sy"
  }, {
    n: "Taiwan (\u53F0\u7063)",
    i: "tw"
  }, {
    n: "Tajikistan",
    i: "tj"
  }, {
    n: "Tanzania",
    i: "tz"
  }, {
    n: "Thailand (\u0E44\u0E17\u0E22)",
    i: "th"
  }, {
    n: "Timor-Leste",
    i: "tl"
  }, {
    n: "Togo",
    i: "tg"
  }, {
    n: "Tokelau",
    i: "tk"
  }, {
    n: "Tonga",
    i: "to"
  }, {
    n: "Trinidad and Tobago",
    i: "tt"
  }, {
    n: "Tunisia (\u202B\u062A\u0648\u0646\u0633\u202C\u200E)",
    i: "tn"
  }, {
    n: "Turkey (T\xFCrkiye)",
    i: "tr"
  }, {
    n: "Turkmenistan",
    i: "tm"
  }, {
    n: "Turks and Caicos Islands",
    i: "tc"
  }, {
    n: "Tuvalu",
    i: "tv"
  }, {
    n: "Uganda",
    i: "ug"
  }, {
    n: "Ukraine (\u0423\u043A\u0440\u0430\u0457\u043D\u0430)",
    i: "ua"
  }, {
    n: "United Arab Emirates (\u202B\u0627\u0644\u0625\u0645\u0627\u0631\u0627\u062A \u0627\u0644\u0639\u0631\u0628\u064A\u0629 \u0627\u0644\u0645\u062A\u062D\u062F\u0629\u202C\u200E)",
    i: "ae"
  }, {
    n: "United Kingdom",
    i: "gb"
  }, {
    n: "United States",
    i: "us"
  }, {
    n: "U.S. Minor Outlying Islands",
    i: "um"
  }, {
    n: "U.S. Virgin Islands",
    i: "vi"
  }, {
    n: "Uruguay",
    i: "uy"
  }, {
    n: "Uzbekistan (O\u02BBzbekiston)",
    i: "uz"
  }, {
    n: "Vanuatu",
    i: "vu"
  }, {
    n: "Vatican City (Citt\xE0 del Vaticano)",
    i: "va"
  }, {
    n: "Venezuela",
    i: "ve"
  }, {
    n: "Vietnam (Vi\u1EC7t Nam)",
    i: "vn"
  }, {
    n: "Wallis and Futuna",
    i: "wf"
  }, {
    n: "Western Sahara (\u202B\u0627\u0644\u0635\u062D\u0631\u0627\u0621 \u0627\u0644\u063A\u0631\u0628\u064A\u0629\u202C\u200E)",
    i: "eh"
  }, {
    n: "Yemen (\u202B\u0627\u0644\u064A\u0645\u0646\u202C\u200E)",
    i: "ye"
  }, {
    n: "Zambia",
    i: "zm"
  }, {
    n: "Zimbabwe",
    i: "zw"
  }], function (i, c) {
    c.name = c.n;
    c.iso2 = c.i;
    delete c.n;
    delete c.i;
  });
});

export default exports;